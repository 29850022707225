<template>
  <div v-if="toastedReactive && toastedReactive.isToasted" :class="$style.toast">
    <div :class="[$style.alert_container, toastedReactive.className]">
      <p :class="$style.txt">{{ toastedReactive.message }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from "~/state"
const toast = useToast()
const toastedReactive = toast?.toastedReactive
</script>

<style module scoped lang="scss">
.toast {
  .alert_container {
    position: fixed;
    top: 6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16rem;
    max-width: 80vw;
    padding: 0.75rem 0.5rem;
    border-radius: 0.25rem;
    @include md {
      top: 8rem;
    }
    .txt {
      @include main-font;
      font-size: $font-size-14;
      font-weight: bold;
      color: $white;
      text-align: center;
    }
  }
}
</style>

<style scoped lang="scss">
._success {
  background: rgba($primary, 0.9) !important;
}
._warning {
  background: rgba($warning, 0.9) !important;
}
._error {
  background: rgba($danger, 0.9) !important;
}
</style>
